import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import { MainCourseTitle } from '@local/components/typography/titles';
import BasicInfo from '@local/components/course/BasicInfo';
import CourseForm from '@local/components/course/CourseForm';
import Requirements from '@local/components/course/Requirements';
import CourseContent from '@local/components/course/CourseContent';
import Place from '@local/components/course/Place';
import PriceList from '@local/components/course/PriceList';
import PriceCard from '@local/components/course/PriceCard';
import TableOfContents from '@local/components/course/TableOfContents';

const Ponuka = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>SOLID princípy v PHP - {data.site.siteMetadata.title}</title>
            </Helmet>
            <Layout>
                <MainCourseTitle>SOLID princípy v PHP</MainCourseTitle>

                <Typography>
                    SOLID princípy predstavujú základ dobrého softvérového návrhu, udržiavateľného a
                    rozširovateľného kódu a mal by ich teda poznať každý programátor. Ich
                    dodržiavanie pomôže udržať váš kód čistý a ľahko upravovateľný.
                </Typography>

                <TableOfContents />

                <BasicInfo>
                    <Typography>
                        Kurz je určený pre programátorov, ktorí ešte nemajú žiadnu skúsenosť so
                        SOLID princípmi alebo si chcú občerstviť vedomosti. Povieme si, z čoho
                        jednotlivé princípy vychádzajú, veľmi krátko si každý z nich teoreticky
                        predstavíme a hneď sa vrhneme na ich aplikáciu v príkladoch.
                    </Typography>

                    <Typography>
                        Príklady sú zo skutočného života, aj keď sú pre účel školenia trochu
                        zjednodušené, aby sme sa zameriavali na podstatu.
                    </Typography>

                    <Typography>
                        Pri každom princípe sa snažím povedať jeho podstatu, prečo je dobré ho
                        dodržiavať, ako spoznať jeho porušenie, kedy je vhodné refaktorovať a kedy
                        je lepšie dodržanie princípu obetovať pragmatickým dôvodom.
                    </Typography>
                </BasicInfo>

                <CourseForm>
                    <Typography>
                        Nejedná sa o samostatný kurz, ale o päť samostatných kurzov, z ktorých každý
                        má za cieľ predstaviť jeden z piatich SOLID princípov. Každý čiastkový kurz
                        trvá zhruba tri hodiny. Najviac sa mi osvedčilo, keď ich robíme samostatne s
                        aspoň niekoľkodňovou prestávkou (ideálne týždeň), pretože tak programátori
                        majú čas sa nad každým princípom zamyslieť a prípadne si ho vyskúšať v
                        práci. Neodporúčam to, ale je možné sa dohodnúť aj na jednorázovom odškolení
                        všetkých princípov spoločne.
                    </Typography>

                    <Typography>
                        Školenia robím formou workshopu, čo znamená, že od uchádzačov vyžadujem, aby
                        mali k dispozícii vlastný počítač a počas školenia budú programovať.
                        Zvyčajne najskôr vysvetlím nejakú teóriu, ukážem príklad, ktorý si odpíšu a
                        potom zadávam úlohu, ktorú musia vypracovať. Počas ich práce chodím pomedzi
                        nich a s každým konzultujem postup a navrhnuté riešenie.
                    </Typography>

                    <Typography>
                        Úlohy zadávam tak, aby ich vypracovanie nezaberalo dlhý čas. Zároveň sa
                        snažím pôsobiť prívetivo a skôr ako partner pri programovaní, než učiteľ,
                        aby som nevytváral účastníkom stres. Koniec koncov je cieľom sa niečo naučiť
                        a nie je to súťaž o najrýchlejšie alebo najlepšie riešenie.
                    </Typography>
                </CourseForm>

                <CourseContent>
                    <ul>
                        <li>Single Responsibility Principle</li>
                        <li>Open-Closed Principle</li>
                        <li>Liskov Substitution Principle</li>
                        <li>Interface Segregation Principle</li>
                        <li>Dependency Inversion Principle</li>
                    </ul>
                </CourseContent>

                <Requirements>
                    <ul>
                        <li>Znalosť programovania v PHP</li>
                        <li>Vlastný notebook alebo počítač</li>
                        <li>
                            Nainštalované PHP vo verzii 7.4 a vyššej (buď lokálne alebo v docker
                            kontajneri)
                        </li>
                    </ul>
                </Requirements>

                <Place>
                    <Typography>Vo vašej firme alebo online.</Typography>
                </Place>

                <PriceList>
                    <PriceCard where="U vás" howMany="Najviac 5 účastníkov" price="1 500,- €" />
                    <PriceCard where="U vás" howMany="Najviac 10 účastníkov" price="1 750,- €" />
                    <PriceCard where="Online" howMany="Najviac 5 účastníkov" price="1 200,- €" />
                </PriceList>
            </Layout>
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default Ponuka;
